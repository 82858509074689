import FacebookImage from '@images/colored-facebook-icon.svg'
import GoogleImage from '@images/colored-google-icon.svg'
import React from 'react'
import './SignSocial.scss'

interface SignSocialProps {}

const SignSocial: React.FC<SignSocialProps> = () => {
  const uri = process.env.GATSBY_PROCESS_URL

  const googleLoginUrl = uri + '/api/authenticate/google'
  const facebookLoginUrl = uri + '/api/authenticate/facebook'

  return (
    <>
      <div className="socials">
        <div className="socials-buttons">
          <a href={googleLoginUrl}>
            <div className="socials-buttons-item">
              <GoogleImage />
              Continue with Google
            </div>
          </a>
          {/* <a href={facebookLoginUrl}>
            <div className="socials-buttons-item">
              <FacebookImage />
              Continue with Facebook
            </div>
          </a> */}
        </div>
      </div>
      <div className="socials-hint">
        <div className="socials-hint-line" />
        <div className="socials-hint-label">or</div>
        <div className="socials-hint-line" />
      </div>
    </>
  )
}

export default SignSocial
