import { gql } from '@apollo/client'
import { LockerAttrs, LockerResponseAttrs } from '@fragments'

export const FetchLocker = gql`
  query FetchLocker {
    res: locker {
      ...LockerAttrs
    }
  }

  ${LockerAttrs}
`

export const CreateLocker = gql`
  mutation CreateLocker($locker: LockerParams!) {
    res: createLocker(locker: $locker) {
      ...LockerResponseAttrs
      result {
        hole
        handicap
        gloveSize
        championshipYear
        championshipRole
        holeInOne{
          id 
          title
        }
        turn{
          id 
          title
        }
        drink{
          id 
          title
        }
        myClub{
          id
          title
        }
        user {
          id
        }
        id
        insertedAt
        updatedAt
      }
    }
  }
  ${LockerResponseAttrs}
`

export const UpdateLocker = gql`
  mutation UpdateLocker($locker: LockerParams) {
    res: updateLocker(locker: $locker) {
      ...LockerResponseAttrs
      result {
        ...LockerAttrs
      }
    }
  }

  ${LockerAttrs}
  ${LockerResponseAttrs}
`

export const DeleteLocker = gql`
  mutation DeleteLocker {
    res: deleteLocker {
      ...LockerResponseAttrs
    }
  }

  ${LockerResponseAttrs}
`
