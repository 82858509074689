import { Input, Loader } from '@elements'
import { useGolfClubModelSearch, useWindowSize } from '@hooks'
import SearchImage from '@images/search.svg'
import { useLocation } from '@reach/router'
import { CatalogItem, CatalogItemType, ProductPageItemType } from '@types'
import { navigate } from 'gatsby'
import React, { memo, useEffect, useRef, useState } from 'react'
import styles from './MobileGolfClubModelSearch.module.scss'

export interface MobileGolfClubModelSearchProps { }

const renderSearchOptions = (catalogItems: CatalogItem[], params: string) => {

  if (!catalogItems || !catalogItems.length) {
    return <div className={styles.mobileAutocompleteListNotAvailable}>No options available</div>
  }

  return (
    <>
      {catalogItems.map(catalogItem => {
        const isGolfClub = catalogItem.type === CatalogItemType.GOLF_CLUB_MODEL
        const itemType: ProductPageItemType = isGolfClub ? 'GolfClubModel' : 'GolfEquipmentModel'
        return (
          <li
            key={catalogItem.id}
            className={styles.mobileAutocompleteListItem}
            onClick={() => navigate(`/product/${catalogItem.slug}/${itemType}`)}
          >
            {`${catalogItem?.brandTitle || ''}  ${catalogItem?.title || ''} - ${catalogItem.categoryTitle || ''}`}
          </li>
        )
      })}
      <li
        className={styles.mobileAutocompleteListItem}
        data-all-results={true}
        onClick={() => navigate('/catalogue' + params)}
      >
        All results
      </li>
    </>
  )
}

const MobileGolfClubModelSearch: React.FC<MobileGolfClubModelSearchProps> = props => {
  const dropdownTimer: React.MutableRefObject<number> = useRef<number>(0)
  const [isSearchDropdownOpened, setIsSearchDropdownOpened] = useState<boolean>(false)

  const { pathname } = useLocation()
  const currentPage = pathname.split('/')[1]

  // determine if component should direct to full catalogue page on Enter
  const shouldRedirect = currentPage.includes('home')

  const { toRender: isMobileRender } = useWindowSize(['mobile', 'landscape'])
  useEffect(() => clearTimeout(dropdownTimer.current), [])

  const {
    search,
    handleSearch,
    handleUrlSearchQueryClear,
    catalogItems,
    loading,
    openCataloguePageWithSearch,
    params
  } = useGolfClubModelSearch()


  const handleOpenSearchDropdown = (isOpen: boolean) => () => {
    if (!isOpen) {
      dropdownTimer.current = (setTimeout(() => setIsSearchDropdownOpened(isOpen), 300) as unknown) as number
    } else {
      setIsSearchDropdownOpened(isOpen)
    }
  }

  if (!isMobileRender) {
    return null
  }

  return (
    <div className={styles.mobileAutocomplete}>
      <Input
        prefix={loading ? <Loader timeout={1000} /> : <SearchImage />}
        type={'text'}
        name={'gcmSearch'}
        placeholder={'Search'}
        onChange={handleSearch}
        onTimesClick={handleUrlSearchQueryClear}
        value={search}
        onFocus={handleOpenSearchDropdown(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsSearchDropdownOpened(false)
          }, 300)

          handleOpenSearchDropdown(false)
        }}
        onKeyDown={e => {
          if (!isSearchDropdownOpened) {
            setIsSearchDropdownOpened(true)
          }
          if (e.key === 'Enter' || e.key === 'Return') {
            setIsSearchDropdownOpened(false)
            e.currentTarget.blur()
            if (shouldRedirect) {
              openCataloguePageWithSearch()
            }
          }
        }}
      />
      <ul className={styles.mobileAutocompleteList} data-open={isSearchDropdownOpened}>
        {renderSearchOptions(catalogItems, params)}
      </ul>
    </div>
  )
}

export default memo(MobileGolfClubModelSearch)
