import { useQuery } from '@apollo/client'
import { Button, Disclosure, Link } from '@elements'
import { filters as graphqlFilters, user as graphqlUser } from '@graphql'
import { useModal, useWindowSize } from '@hooks'
import TourDayLogo from '@images/tourday/tourday-logo.svg'
import NGFLogo from '@images/national-golf-foundation-logo.svg'
import ClubmakersLogo from '@images/clubmakers-guild-logo.svg'
import WinkFaceIcon from '@images/wink-face.svg'
import { CacheUser, Filters, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { memo } from 'react'
import styles from './Footer.module.scss'

export interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  const { openModal: openSuggestChangesModal } = useModal('SuggestChangesModal')

  const { toRender: isMobile } = useWindowSize(['mobile', 'landscape'])

  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, { fetchPolicy: 'cache-only' })
  const categories = data?.res.categories.map(category => {
    return {
      label: category.title,
      value: `/catalogue/?mySettings=false&categoryIds=${category.id}`
    }
  })

  const guidesLinks = [
    { label: 'Buying Guide', value: '/about/buying/' },
    { label: 'Selling Guide', value: '/about/selling/' },
    { label: 'Condition Guide', value: '/about/condition/' },
    { label: 'Shipping Guide', value: '/about/shipping/' }
  ]
  const aboutLinks = [
    { label: 'How It Works', value: '/about/' },
    { label: 'FAQ', value: '/FAQ/' },
    { label: 'Terms & Conditions', value: '/TERMS/' }
  ]

  const handleScrollToTop = () => {
    window.scrollTo(0, 0)
  }

  const { data: currentUser } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const isLogin = Boolean(currentUser?.res?.id)
  const renderBottomSection = () => {
    if (isMobile) {
      return (
        <div className={styles.footerBottomSectionMobile}>
          {[categories, aboutLinks, guidesLinks]?.map((list, index) => {
            const name = index === 0 ? 'Catalog' : index === 1 ? 'About Us' : 'Guides'

            return (
              <Disclosure key={name} label={name}>
                <div className={styles.footerLinksList}>
                  <ul>
                    <li />
                    {list?.map(listItem => (
                      <li key={listItem.label} onClick={handleScrollToTop}>
                        <Link to={listItem.value}>{listItem.label}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Disclosure>
            )
          })}
        </div>
      )
    }
    return (
      <div className={styles.footerBottomSection}>
        <div className={styles.footerBottomSectionDescription}>
          <TourDayLogo onClick={() => navigate('/home')} />
          TourDay Golf is a premier destination to buy and sell new and pre-owned golf equipment.
          <br />
          <br />
          For questions about buying and selling on TourDay, contact our Concierge via text at 929-324-0457 or email at{' '}
          <a className={styles.footerBottomSectionDescriptionLink} href="mailto:concierge@tourdaygolf.com">
            concierge@tourdaygolf.com
          </a>
          <br />
          <br />
          <b>Based in Greenwich, CT</b>
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <NGFLogo style={{ height: 40, width: 90 }} />
            <ClubmakersLogo style={{ marginLeft: 20, height: 40, width: 180 }} />
          </div>
        </div>
        <div className={styles.footerLinks}>
          {[categories, aboutLinks, guidesLinks]?.map((list, index) => {
            const name = index === 0 ? 'Catalog' : index === 1 ? 'About Us' : 'Guides'
            return (
              <div key={name} className={styles.footerLinksList}>
                <ul>
                  <li>{name}</li>
                  {list?.map(listItem => (
                    <li key={listItem.label} onClick={handleScrollToTop}>
                      <Link to={listItem.value}>{listItem.label}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className={styles.footer} role="contentinfo">
      <div>
        {isLogin && (
          <div className={styles.footerBlock}>
            <div className={styles.footerBlockTitle}>
              Is there a club you’d like to buy or sell that is not in our library?
            </div>

            <div className={styles.footerBlockButton}>
              <Button onClick={() => openSuggestChangesModal()} type="black">
                Fill out the form
              </Button>
              <div className={styles.footerBlockHint}>
                <WinkFaceIcon />
                And we will review.
              </div>
            </div>
          </div>
        )}
        {renderBottomSection()}
      </div>
    </div>
  )
}

export default memo(Footer)
