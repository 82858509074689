import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { PriceTaxes, PriceTaxesArgs, ResponseType } from '@types'
import { GetPaymentInfo } from '@graphql'
import { useLocationWithState } from '@hooks'
import styles from './OrderPriceList.module.scss'

interface OrderPriceListProps {
  price?: number
  addressCardId?: string
  promoCodeId?: string
}

const OrderPriceList: React.FC<OrderPriceListProps> = ({ price, addressCardId, promoCodeId }) => {
  const [fetchTaxes, { data: priceTaxesData }] = useLazyQuery<ResponseType<PriceTaxes>, PriceTaxesArgs>(
    GetPaymentInfo,
    {
      fetchPolicy: 'no-cache'
    }
  )

  const priceTaxes = priceTaxesData?.res
  const location = useLocationWithState()

  const buyerList =
    location.pathname.includes('buy') ||
    location.pathname.includes('order-history') ||
    location.pathname.includes('my-sales-history')

  useEffect(() => {
    if (price && addressCardId) {
      debounce(() => fetchTaxes({ variables: { price, addressCardId, promoCodeId } }), 1000)()
    }
  }, [price, addressCardId])

  const renderSalesTax = () => {
    if (!priceTaxes) return <span>...</span>
    return <span>{`$ ${price === 0 ? 0 : priceTaxes.buyerSalesTax.toFixed(2)}`}</span>
  }

  const renderProcessingFee = () => {
    if (!priceTaxes) return <span>...</span>
    return <span>{`$ ${price === 0 ? 0 : priceTaxes.sellerProcessingFee.toFixed(2)}`}</span>
  }

  const renderShipping = () => {
    if (!priceTaxes) return <span>...</span>
    const shippingPrice = buyerList ? priceTaxes.buyerShippingPrice : priceTaxes.sellerShippingPrice
    return <span>{`$ ${shippingPrice.toFixed(2)}`}</span>
  }

  const renderTotal = () => {
    if (!priceTaxes) return <span>...</span>
    const totalSellerPriceWithProcessingFee = priceTaxes?.itemPrice - +priceTaxes?.sellerTotalPrice.toFixed(2)

    const totalPrice = buyerList ? priceTaxes?.buyerTotalPrice : totalSellerPriceWithProcessingFee
    return <span>{`$ ${price === 0 ? 0 : totalPrice.toFixed(2)}`}</span>
  }

  return (
    <div className={styles.orderPriceList}>
      <div className={styles.orderPriceListItem}>
        <div className={'grey uppercase'}>
          <strong>Price</strong>
        </div>
        <p>{`$ ${price || 0}`}</p>
      </div>
      {buyerList && (
        <div className={styles.orderPriceListItem}>
          <div className={'grey uppercase'}>
            <strong>Sales Tax</strong>
          </div>
          {renderSalesTax()}
        </div>
      )}
      {!buyerList && (
        <div className={styles.orderPriceListItem}>
          <div className={'grey uppercase'}>
            <strong>Processing Fee</strong>
          </div>
          {renderProcessingFee()}
        </div>
      )}
      <div className={styles.orderPriceListItem}>
        <div className={'grey uppercase'}>
          <strong>SHIPPING</strong>
        </div>
        {renderShipping()}
      </div>
      <div className={styles.orderPriceListItem}>
        <div className={'uppercase'} style={{ color: '#333333' }}>
          <b>Total</b>
        </div>
        <p>
          <b>{renderTotal()}</b>
        </p>
      </div>
    </div>
  )
}

export default OrderPriceList
