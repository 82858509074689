import { gql } from '@apollo/client'

export const GolfEquipmentModelMarketDataHistoryAttrs = gql`
  fragment GolfEquipmentModelMarketDataHistoryAttrs on GolfEquipmentModel {
    golfEquipmentTypes {
      condition
      id
      option
      updatedAt
      purchaseRequests {
        itemPrice
        id
        status
        updatedAt
      }
      golfEquipments {
        id
        price
        status
        updatedAt
      }
    }
    id
  }
`
