import { GolfClub, PromoCode, User } from '../apollo'
import { GolfEquipment } from './equipment'

export type Order = {
  address: OrderAddress
  golfEquipment: GolfEquipment
  buyerSalesTax: number
  buyerShippingPrice: number
  buyerTotalPrice: number
  golfClub: GolfClub
  itemPrice: number
  id: string
  insertedAt: string
  number: number
  paid: boolean
  paidBySeller: boolean
  promoCode: PromoCode
  seller: User
  sellerProcessingFee: number
  sellerProfit: number
  sellerShippingPrice: number
  sellerTotalPrice: number
  status: OrderStatus
  stripeSessionId: string
  updatedAt: string
  user: User
}

export type OrderAddress = {
  address: string
  addressLine: string
  city: string
  country: string
  name: string
  phoneNumber: string
  state: string
  stateCode: string
  zipCode: string
}

export type PaginatedOrders = {
  entries: [Order]
  pageNumber: number
  pageSize: number
  totalEntries: number
  totalPages: number
}

export enum OrderStatusEnum {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILURE = 'FAILURE',
  PAID = 'PAID'
}

enum OrderStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  DELIVERED_TO_BUYER = 'DELIVERED_TO_BUYER',
  DELIVERED_TO_HOTCAKE = 'DELIVERED_TO_HOTCAKE',
  FAILURE = 'FAILURE',
  IN_TRANSIT_TO_BUYER = 'IN_TRANSIT_TO_BUYER',
  IN_TRANSIT_TO_HOTCAKE = 'IN_TRANSIT_TO_HOTCAKE',
  PAID = 'PAID',
  VERIFIED_BY_HOTCAKE = 'VERIFIED_BY_HOTCAKE'
}
