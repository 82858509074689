import { gql } from '@apollo/client'
import { ValidationMessageAttrs } from '@fragments'

export const PurchaseRequestAttr = gql`
  fragment PurchaseRequestAttr on PurchaseRequest {
    buyerSalesTax
    buyerShippingPrice
    buyerTotalPrice
    expiresAt
    itemPrice
    id
    insertedAt
    sellerProcessingFee
    sellerProfit
    sellerShippingPrice
    sellerTotalPrice
    status
    updatedAt
    golfClubType {
      id
      condition
      flex
      dexterity
      option
      golfClubModel {
        avatar
        title
        releaseYear
        slug
        brand {
          title
        }
        category {
          title
        }
      }
    }

    golfEquipmentType {
      id
      condition
      option
      golfEquipmentModel {
        avatar
        title
        releaseYear
        slug
        brand {
          title
        }
        category {
          title
        }
      }
    }
    user {
      id
    }
  }
`

export const PurchaseRequestWithGolfClubModelAttr = gql`
  fragment PurchaseRequestWithGolfClubModelAttr on PurchaseRequest {
    buyerSalesTax
    buyerShippingPrice
    buyerTotalPrice
    expiresAt
    itemPrice
    id
    insertedAt
    sellerProcessingFee
    sellerProfit
    sellerShippingPrice
    sellerTotalPrice
    status
    updatedAt
    golfClubType {
      id
      condition
      flex
      dexterity
      option
      golfClubModel {
        releaseYear
        id
        slug
        title
        retailPrice
        brand {
          id
          title
        }
        category {
          title
          id
        }
        avatar
      }
    }
    user {
      id
    }
  }
`

export const PurchaseRequestResponseAttr = gql`
  fragment PurchaseRequestResponseAttr on PurchaseRequestChangeset {
    messages {
      ...ValidationMessageAttrs
    }
    result {
      id
      buyerSalesTax
      buyerShippingPrice
      buyerTotalPrice
      expiresAt
      user {
        id
      }
      itemPrice
    }
    successful
  }

  ${ValidationMessageAttrs}
`
