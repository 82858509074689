import { navigate } from 'gatsby'
import React, { memo } from 'react'
import { useMatch, useLocation } from '@reach/router'
import { Link, Collapse, PopupMenu } from '@elements'
import { NavigationType, NavigationItemType, BrowsePage, ResponseType } from '@types'
import LinkArrowImage from '@images/collapse-arrow.svg'
import styles from './Navigation.module.scss'
import { useQuery } from '@apollo/client'
import { browsePage } from '@graphql'

interface NavigationItemProps extends NavigationItemType {
  activeMatch?: boolean
}

const NavigationItem: React.FC<NavigationItemProps> = props => {
  const { path, label, icon, activeMatch, subPages } = props
  const { search, pathname } = useLocation()

  const match = !!path.length && pathname.includes(path)
  const isStorePage = !!useMatch('/account/store/*')

  const isUriMatched = !!(activeMatch && match)
  const linkHref = match ? path + search : path
  const handleSubItemClick = async () => {
    await navigate(linkHref)
  }

  return (
    <>
      {subPages ? (
        <div className={styles.itemWithSub}>
          <li
            className={styles.itemWithSubMain}
            key={path}
            data-active={isUriMatched}
            tabIndex={0}
            onClick={handleSubItemClick}
          >
            <div className={styles.itemWithSubLabel}>
              {icon && <img src={icon} alt={label} />}
              <Link to={linkHref} replace={true} active={isUriMatched}>
                {label}
              </Link>
            </div>
            <LinkArrowImage />
          </li>
          <Collapse isOpened={isStorePage}>
            {subPages.map((link, index) => (
              <NavigationItem key={link.label + index} {...link} />
            ))}
          </Collapse>
        </div>
      ) : (
        <li
          key={path}
          data-active={isUriMatched}
          tabIndex={0}
          onClick={() => navigate(linkHref)}
          style={{ marginBottom: 0, textTransform: 'uppercase' }}
        >
          {icon && <img src={icon} alt={label} />}
          <Link to={linkHref} active={isUriMatched}>
            {label}
          </Link>
        </li>
      )}
    </>
  )
}

interface NavigationProps {
  navigation: NavigationType
  direction?: string
  activeMatch?: boolean
}

interface BrowsePageItem {
  label: string
  value: string
}

const Navigation: React.FC<NavigationProps> = props => {
  const { navigation, direction, activeMatch } = props

  // initialize link options with home page & full catalogue
  const defaultPages: BrowsePageItem[] = [
    { label: 'HOME', value: '/home' },
    { label: 'FULL CATALOGUE', value: '/catalogue' },
    { label: 'ABOUT', value: '/about' }
  ]

  return (
    <nav role={'navigation'} data-direction={direction} className={styles.wrapper}>
      <ul>
        {navigation.map((item, index) => {
          if (item.path === '/catalogue/') {
            return (
              <PopupMenu key={item.label + index} position={'bottom left'} options={defaultPages}>
                <NavigationItem {...item} activeMatch={activeMatch} />
              </PopupMenu>
            )
          }
          return <NavigationItem key={item.label + index} {...item} activeMatch={activeMatch} />
        })}
      </ul>
    </nav>
  )
}

export default memo(Navigation)
