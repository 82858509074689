import React from 'react'
import { useQuery } from '@apollo/client'
import { Select, SelectProps } from '@components'
import { Filters, OptionType, ResponseType } from '@types'
import { filters as graphqlFilters } from '@graphql'
import styles from './FlexSelect.module.scss'



export interface FlexSelectProps extends Omit<SelectProps, 'options'> {
  defaultValue?: string | number | Record<string, any>
  userSelect?: boolean
  customFlexOptions?: OptionType[]
}

const FlexSelect: React.FC<FlexSelectProps> = props => {
  const { userSelect, customFlexOptions } = props
  const { data } = useQuery<ResponseType<Filters>>(graphqlFilters.FetchFilters, {
    fetchPolicy: 'cache-only'
  })

  const filters = data ? data.res : ({} as Filters)
  const { flexes = [] } = filters
  const options = userSelect ? [...flexes, 'Not Sure'] : flexes

  const queryFlexOptions = options
    .filter(flex => flex.length)
    .map(flex => {
      return {
        label: flex
          .split('_')
          .map(el => {
            return el.charAt(0).toUpperCase() + el.substring(1)
          })
          .join(' '),
        value: flex.toUpperCase()
      }
    })

  const flexOptions = customFlexOptions || queryFlexOptions

  return (
    <div className={styles.flexSelect}>
      <Select options={flexOptions} {...props} />
    </div>
  )
}

export default FlexSelect
