import React, { memo } from 'react'
import { Select, SelectProps } from '@components'
import { capitalize } from 'lodash'
import styles from './DexteritySelect.module.scss'
import { dexterities } from '@utils'

export interface DexteritySelectProps extends Omit<SelectProps, 'options'> {
  defaultValue?: string | number | Record<string, any>
}

const DexteritySelect: React.FC<DexteritySelectProps> = props => {
  const dexteritiesOptions = dexterities
    .map(dexterity => {
      return {
        label: capitalize(dexterity),
        value: dexterity
      }
    })
    .reverse()

  return (
    <div className={styles.dexteritySelect}>
      <Select options={dexteritiesOptions} {...props} />
    </div>
  )
}

export default memo(DexteritySelect)
