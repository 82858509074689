import React, { memo, forwardRef, useState, RefObject } from 'react'
import { Loader } from '@elements'
import { handleEvent } from '@utils'
import TimesImage from '@images/times.svg'
import NotAllowedImage from '@images/not-allowed.svg'
import './Input.scss'

export type InputSuffixProp = JSX.Element | string | number
export interface InputProps {
  name: string
  value: string
  type?: string
  label?: string
  defaultValue?: string
  placeholder?: string
  cursor?: string
  prefix?: InputSuffixProp
  secondPrefix?: InputSuffixProp
  suffix?: InputSuffixProp

  autoComplete?: 'on' | 'off' | 'disabled'
  disabled?: boolean
  loading?: boolean
  error?: boolean
  clearable?: boolean
  required?: boolean
  rounded?: boolean
  hideCursor?: boolean
  role?: string
  direction?: 'vertical' | 'horizontal'
  ref?: RefObject<HTMLInputElement>
  readOnly?: boolean
  labelClassName?: string

  onChange?: (value: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
  onSecondPrefixClick?: () => void
  theme?: 'neutral' | 'green'
}

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    name,
    value,
    type,
    label,
    defaultValue,
    placeholder,
    prefix,
    secondPrefix,
    suffix,
    autoComplete,
    disabled,
    error,
    loading,
    clearable,
    required,
    rounded,
    hideCursor,
    role,
    direction,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClear,
    onSecondPrefixClick,
    readOnly,
    labelClassName,
    theme = 'neutral'
  } = props

  const [focused, focus] = useState(false)

  /* If disabled no focus */
  const tabIndex = disabled ? -1 : 0

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    handleEvent(onChange, { value: e.currentTarget.value, disabled })
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    focus(false)
    handleEvent(onBlur, { value: e, disabled })
  }
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    focus(true)
    handleEvent(onFocus, { value: e, disabled })
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => handleEvent(onKeyDown, { value: e, disabled })
  const inputClass = 'input-inner'
  const handleClear = () => {
    if (!disabled) {
      onChange && onChange('')
    }
  }

  return (
    <div className={`input ${theme}`} data-disabled={disabled} data-direction={direction}>
      {label && (
        <label htmlFor={name} data-required={required} className={labelClassName}>
          {label}
        </label>
      )}
      <div className={'input-inner'} data-error={error} data-focused={focused} data-rounded={rounded}>
        <div className={'input-inner__prefix'}>{prefix}</div>
        <input
          id={name}
          type={type}
          name={name}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          autoComplete={autoComplete}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onClick={e => {
            if (readOnly) {
              e.preventDefault()
            }
          }}
          data-cursor={hideCursor}
          role={role}
          tabIndex={tabIndex}
          readOnly={readOnly}
        />
        <div className={'input-inner__suffix'}>
          {loading && <Loader type={'Oval'} width={16} height={16} style={{ width: 'auto', padding: '0' }} />}
          {suffix}
          {clearable && <TimesImage className={'input-inner__suffix-times'} onClick={onClear || handleClear} />}
          {disabled && <NotAllowedImage className={'input-inner__suffix-not-allowed'} />}
          {value.length > 0 && secondPrefix && (
            <div className={'input-inner__secondPrefix'} onClick={onSecondPrefixClick}>
              {secondPrefix}
            </div>
          )}
        </div>
      </div>
    </div>
  )
})

Input.displayName = 'Input'
Input.defaultProps = {
  type: 'text',
  placeholder: 'Enter text',
  autoComplete: 'on',
  clearable: true,
  rounded: false,
  cursor: 'text'
}

export default memo(Input)
