import { Fallback } from '@components'
import loadable from '@loadable/component'
import React from 'react'

export const Button = loadable(() => import('./Button/Button'), {
  fallback: <Fallback height={'40px'} />
})
export const ButtonWithPlus = loadable(() => import('./ButtonWithPlus/ButtonWithPlus'), {
  fallback: <Fallback height={'20px'} />
})
export const ButtonWithShare = loadable(() => import('./ButtonWithShare/ButtonWithShare'), {
  fallback: <Fallback height={'16px'} />
})
export const ButtonWithRefresh = loadable(() => import('./ButtonWithRefresh/ButtonWithRefresh'), {
  fallback: <Fallback height={'12px'} />
})

export const Image = loadable(() => import('./Image/Image'))
export const Link = loadable(() => import('./Link/Link'))
export const Input = loadable(() => import('./Input/Input'), {
  fallback: <Fallback height={'32px'} />
})

export const RadioButton = loadable(() => import('./RadioButton/RadioButton'))
export const EmptyTable = loadable(() => import('./EmptyTable/EmptyTable'))
export const Checkbox = loadable(() => import('./Checkbox/Checkbox'))
export const Group = loadable(() => import('./Group/Group'))
export const Option = loadable(() => import('./Option/Option'), {
  fallback: <Fallback height={'44px'} />
})

export const DeleteButton = loadable(() => import('./DeleteButton/DeleteButton'))
export const Popup = loadable(() => import('./Popup/Popup'))
export const PopupMenu = loadable(() => import('./PopupMenu/PopupMenu'))
export const Tooltip = loadable(() => import('./Tooltip/Tooltip'))
export const Bullets = loadable(() => import('./Bullets/Bullets'))
export const StatusIcon = loadable(() => import('./StatusIcon/StatusIcon'))
export const Switcher = loadable(() => import('./Switcher/Switcher'), {
  fallback: <Fallback height={'36px'} />
})
export const SettingsSwitcher = loadable(() => import('./SettingsSwitcher/SettingsSwitcher'))
export const PageSwitcher = loadable(() => import('./PageSwitcher/PageSwitcher'), {
  fallback: <Fallback height={'36px'} />
})
export const Navigation = loadable(() => import('./Navigation/Navigation'))
export const Collapse = loadable(() => import('./Collapse/Collapse'))
export const CollapseWithSearch = loadable(() => import('./CollapseWithSearch/CollapseWithSearch'))
export const Alert = loadable(() => import('./Alert/Alert'))
export const Loader = loadable(() => import('./Loader/Loader'))
export const Pagination = loadable(() => import('./Pagination/Pagination'), {
  fallback: <Fallback height={'25px'} />
})

export const ButtonWithPencil = loadable(() => import('./ButtonWithPencil/ButtonWithPencil'))
export const GolfClubInfo = loadable(() => import('./GolfClubInfo/GolfClubInfo'))
export const AvatarCard = loadable(() => import('./AvatarCard/AvatarCard'))
export const ValidationErrorMessage = loadable(() => import('./ValidationErrorMessage/ValidationErrorMessage'))
export const Carousel = loadable(() => import('./Carousel/Carousel'), {
  fallback: <Fallback height={'200px'} />
})
export const InfoGrid = loadable(() => import('./InfoGrid/InfoGrid'))
export const OrderPriceList = loadable(() => import('./OrderPriceList/OrderPriceList'), {
  fallback: <Fallback height={'226px'} />
})
export const OrderPaymentDetails = loadable(() => import('./OrderPaymentDetails/OrderPaymentDetails'), {
  fallback: <Fallback height={'20px'} />
})
export const CheckOption = loadable(() => import('./CheckOption/CheckOption'), {
  fallback: <Fallback height={'20px'} />
})

export const NoDataYet = loadable(() => import('./NoDataYet/NoDataYet'), {
  fallback: <Fallback height={'20px'} />
})

export const CircleIconButton = loadable(() => import('./CircleIconButton/CircleIconButton'))

export const Dashboard = loadable(() => import('./Dashboard/Dashboard'))
export const DragAndDropFile = loadable(() => import('./DragAndDropFile/DragAndDropFile'))
export const MobileGolfClubCard = loadable(() => import('./MobileGolfClubCard/MobileGolfClubCard'))
export const MobileSaleCard = loadable(() => import('./MobileSaleCard/MobileSaleCard'))
export const SellerCard = loadable(() => import('./SellerCard/SellerCard'))
export const UserAddress = loadable(() => import('./UserAddress/UserAddress'))
export const Disclosure = loadable(() => import('./Disclosure/Disclosure'))

export * from './Alert/Alert'
export * from './AvatarCard/AvatarCard'
export * from './Bullets/Bullets'
export * from './Button/Button'
export * from './ButtonWithPencil/ButtonWithPencil'
export * from './ButtonWithPlus/ButtonWithPlus'
export * from './ButtonWithRefresh/ButtonWithRefresh'
export * from './ButtonWithShare/ButtonWithShare'
export * from './Carousel/Carousel'
export * from './Checkbox/Checkbox'
export * from './CheckOption/CheckOption'
export * from './Collapse/Collapse'
export * from './CollapseWithSearch/CollapseWithSearch'
export * from './Dashboard/Dashboard'
export * from './Disclosure/Disclosure'
export * from './Dropdown/Dropdown'
export { default as Dropdown } from './Dropdown/Dropdown'
export * from './GolfClubInfo/GolfClubInfo'
export * from './Group/Group'
export * from './Image/Image'
export * from './InfoGrid/InfoGrid'
export * from './Input/Input'
export * from './Link/Link'
export * from './Loader/Loader'
export * from './MobileSaleCard/MobileSaleCard'
export * from './Navigation/Navigation'
export * from './NoDataYet/NoDataYet'
export * from './Option/Option'
export * from './OrderPaymentDetails/OrderPaymentDetails'
export * from './OrderPriceList/OrderPriceList'
export * from './PageSwitcher/PageSwitcher'
export * from './Pagination/Pagination'
export * from './Popup/Popup'
export * from './PopupMenu/PopupMenu'
export * from './ProductSubscription/ProductSubscription'
export * from './RadioButton/RadioButton'
export * from './SellerCard/SellerCard'
export * from './SettingsSwitcher/SettingsSwitcher'
export * from './Switcher/Switcher'
export * from './Tooltip/Tooltip'
export * from './UserAddress/UserAddress'
export * from './ValidationErrorMessage/ValidationErrorMessage'
