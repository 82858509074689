import { gql } from '@apollo/client'
import {
  GolfEquipmentModelAttrs,
  GolfEquipmentResponseAttrs,
  GolfEquipmentTypeAttrs,
  ValidationMessageAttrs
} from '@fragments'

export const FetchGolfEquipmentModelSlug = gql`
  query GolfEquipmentModelSlug($slug: String!) {
    res: golfEquipmentModelSlug(slug: $slug) {
      ...GolfEquipmentModelAttrs
    }
  }
  ${GolfEquipmentModelAttrs}
`

export const FetchGolfEquipmentModel = gql`
  query GolfEquipmentModel($id: UUID4!) {
    res: golfEquipmentModel(id: $id) {
      ...GolfEquipmentModelAttrs
    }
  }
  ${GolfEquipmentModelAttrs}
`

export const FetchRelatedEquipments = gql`
  query RelatedEquipments($golfEquipmentModelId: UUID4!) {
    res: relatedEquipments(golfEquipmentModelId: $golfEquipmentModelId) {
      mostViewed {
        id
        title
        avatar
        slug
        description
        releaseYear
        retailPrice
        highestBuyPrice
        lowestSellPrice
      }
      related {
        id
        title
        avatar
        slug
        description
        releaseYear
        retailPrice
        highestBuyPrice
        lowestSellPrice
      }
    }
  }
`

export const FetchGolfEquipmentType = gql`
  query GolfEquipmentType($id: UUID4!) {
    res: golfEquipmentType(id: $id) {
      ...GolfEquipmentTypeAttrs
    }
  }
  ${GolfEquipmentTypeAttrs}
`

export const CreateGolfEquipment = gql`
  mutation CreateGolfEquipment($golfEquipment: GolfEquipmentParams) {
    createGolfEquipment(golfEquipment: $golfEquipment) {
      messages {
        ...ValidationMessageAttrs
      }
      successful
    }
  }
  ${ValidationMessageAttrs}
`

export const UpdateGolfEquipment = gql`
  mutation UpdateGolfEquipment($id: UUID4!, $golfEquipment: GolfEquipmentParams) {
    res: updateGolfEquipment(id: $id, golfEquipment: $golfEquipment) {
      ...GolfEquipmentResponseAttrs
    }
  }

  ${GolfEquipmentResponseAttrs}
`

export const ExtendGolfEquipment = gql`
  mutation ExtendGolfEquipment($expiresAt: Date!, $id: UUID4!) {
    res: extendGolfEquipment(expiresAt: $expiresAt, id: $id) {
      ...GolfEquipmentResponseAttrs
      result {
        id
        status
      }
    }
  }
  ${GolfEquipmentResponseAttrs}
`

export const DeleteGolfEquipment = gql`
  mutation DeleteGolfEquipment($id: UUID4!) {
    res: deleteGolfEquipment(id: $id) {
      ...GolfEquipmentResponseAttrs
    }
  }
  ${GolfEquipmentResponseAttrs}
`

export const CreateGolfEquipmentModelAlert = gql`
  mutation CreateGolfEquipmentModelAlert($golfEquipmentModelAlert: GolfEquipmentModelAlertParams!) {
    res: createGolfEquipmentModelAlert(golfEquipmentModelAlert: $golfEquipmentModelAlert) {
      successful
      messages {
        ...ValidationMessageAttrs
      }
    }
  }
  ${ValidationMessageAttrs}
`
export const FetchGolfEquipmentModelOptions = gql`
  query FetchGolfEquipmentModelOptions($golfEquipmentModelId: UUID4!) {
    res: golfEquipmentModelOptions(golfEquipmentModelId: $golfEquipmentModelId)
  }
`
