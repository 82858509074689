import loadable from '@loadable/component'
import React from 'react'
import Fallback from './Fallback/Fallback'

export const Page = loadable(() => import('./Page/Page'))
export const Container = loadable(() => import('./Container/Container'))
export const Header = loadable(() => import('./Header/Header'))
export const Footer = loadable(() => import('./Footer/Footer'))
export const List = loadable(() => import('./List/List'), { fallback: <Fallback height={'343px'} /> })
export const ListSection = loadable(() => import('./ListSection/ListSection'), {
  fallback: <Fallback height={'370px'} />
})
export const PaginatedList = loadable(() => import('./PaginatedList/PaginatedList'), {
  fallback: <Fallback height={'370px'} />
})
export const AvatarPopupMenu = loadable(() => import('./AvatarPopupMenu/AvatarPopupMenu'))
export const ActionsPopupMenu = loadable(() => import('./ActionsPopupMenu/ActionsPopupMenu'))
export const NotificationPopupMenu = loadable(() => import('./NotificationPopupMenu/NotificationPopupMenu'))
export const LocationTabs = loadable(() => import('./LocationTabs/LocationTabs'), {
  fallback: <Fallback height={'33px'} />
})
export const AccountNavigation = loadable(() => import('./AccountNavigation/AccountNavigation'))
export const CategoryLocationTabs = loadable(() => import('./CategoryLocationTabs/CategoryLocationTabs'))
export const Sections = loadable(() => import('./Sections/Sections'))

export const GolfClubModelSearch = loadable(() => import('./GolfClubsSearch/GolfClubModelSearch'), {
  fallback: <Fallback height={'47px'} />
})
export const DesktopGolfClubHeaderSearch = loadable(
  () => import('./DesktopGolfClubHeaderSearch/DesktopGolfClubHeaderSearch'),
  {
    fallback: <Fallback height={'47px'} />
  }
)

export const GolfClubsSearchInput = loadable(() => import('./GolfClubsSearchInput/GolfClubsSearchInput'))

export const GolfClubModelCard = loadable(() => import('./GolfClubModelCard/GolfClubModelCard'), {
  fallback: <Fallback height={'300px'} />
})
export const TourOnlyGolfClubModelCard = loadable(
  () => import('./TourOnlyGolfClubModelCard/TourOnlyGolfClubModelCard'),
  {
    fallback: <Fallback height={'300px'} />
  }
)
export const GolfClubModelsListSection = loadable(() => import('./GolfClubModelsListSection/GolfClubModelsListSection'))
export const BrandCard = loadable(() => import('./BrandCard/BrandCard'), {
  fallback: <Fallback height={'250px'} />
})
export const BrandsListSection = loadable(() => import('./BrandsListSection/BrandsListSection'), {
  fallback: <Fallback height={'370px'} />
})
export const Table = loadable(() => import('./Table/Table'), {
  fallback: <Fallback height={'370px'} />
})

export const SEO = loadable(() => import('./SEO'))
export const ErrorBoundary = loadable(() => import('./ErrorBoundary/ErrorBoundary'))

export const Modals = loadable(() => import('./Modals/Modals'))
export const Drawers = loadable(() => import('./Drawers/Drawers'))

export const GolfClubModelAutocomplete = loadable(
  () => import('./GolfClubModelAutocomplete/GolfClubModelAutocomplete'),
  { fallback: <Fallback height={'40px'} /> }
)
export const CategorySelect = loadable(() => import('./CategorySelect/CategorySelect'), {
  fallback: <Fallback height={'40px'} />
})

export const GripSizeSelect = loadable(() => import('./GripSizeSelect/GripSizeSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const GenderSelect = loadable(() => import('./GenderSelect/GenderSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const HeightSelect = loadable(() => import('./HeightSelect/HeightSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const DexteritySelect = loadable(() => import('./DexteritySelect/DexteritySelect'), {
  fallback: <Fallback height={'40px'} />
})

export const FlexSelect = loadable(() => import('./FlexSelect/FlexSelect'), {
  fallback: <Fallback height={'40px'} />
})
export const ExperienceLevelSelect = loadable(() => import('./ExperienceLevelSelect/ExperienceLevelSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const ShaftSelect = loadable(() => import('./ShaftSelect/ShaftSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const LengthSelect = loadable(() => import('./LengthSelect/LengthSelect'), {
  fallback: <Fallback height={'40px'} />
})
export const ProfilePayout = loadable(() => import('./ProfilePayout/ProfilePayout'))

export const AddressCardSelect = loadable(() => import('./AddressCardSelect/AddressCardSelect'), {
  fallback: <Fallback height={'40px'} />
})

export const Select = loadable(() => import('./Select/Select'), {
  fallback: <Fallback height={'40px'} />
})

export const Autocomplete = loadable(() => import('./Autocomplete/Autocomplete'), {
  fallback: <Fallback height={'40px'} />
})

export const ProfilePayment = loadable(() => import('./ProfilePayment/ProfilePayment'), {
  fallback: <Fallback height={'100vh'} />
})

export const ProfileShipping = loadable(() => import('./ProfileShipping/ProfileShipping'), {
  fallback: <Fallback height={'100vh'} />
})

export const BreadCrumbs = loadable(() => import('./BreadCrumbs'), {
  fallback: <Fallback height={'100vh'} />
})

export const ProductPageButtons = loadable(() => import('./ProductPageButtons'), {
  fallback: <Fallback height={'100vh'} />
})

export const MobileGolfClubModelSearch = loadable(() => import('./MobileGolfClubModelSearch/MobileGolfClubModelSearch'))
export const SignSocial = loadable(() => import('./SignSocial/SignSocial'))

export { default as Drawer } from './Drawer/Drawer'
export { default as Fallback } from './Fallback/Fallback'
export { default as Form } from './Form/Form'
export { default as QueryResponseHandler } from './QueryResponseHandler/QueryResponseHandler'

export * from './AccountNavigation/AccountNavigation'
export * from './ActionsPopupMenu/ActionsPopupMenu'
export * from './AddressCardSelect/AddressCardSelect'
export * from './Autocomplete/Autocomplete'
export * from './AvatarPopupMenu/AvatarPopupMenu'
export * from './BrandCard/BrandCard'
export * from './BrandsListSection/BrandsListSection'
export * from './CategoryLocationTabs/CategoryLocationTabs'
export * from './CategorySelect/CategorySelect'
export * from './Container/Container'
export * from './DexteritySelect/DexteritySelect'
export * from './ErrorBoundary/ErrorBoundary'
export * from './Fallback/Fallback'
export * from './FlexSelect/FlexSelect'
export * from './Footer/Footer'
export * from './Form/Form'
export * from './GenderSelect/GenderSelect'
export * from './GolfClubModelAutocomplete/GolfClubModelAutocomplete'
export * from './GolfClubModelCard/GolfClubModelCard'
export * from './GolfClubModelsListSection/GolfClubModelsListSection'
export * from './GolfClubsSearch/GolfClubModelSearch'
export * from './GolfClubsSearchInput/GolfClubsSearchInput'
export * from './GripSizeSelect/GripSizeSelect'
export * from './Header/Header'
export * from './HeightSelect/HeightSelect'
export * from './LengthSelect/LengthSelect'
export * from './List/List'
export * from './ListSection/ListSection'
export * from './LocationTabs/LocationTabs'
export * from './MobileGolfClubModelSearch/MobileGolfClubModelSearch'
export * from './Modals/Modals'
export * from './NotificationPopupMenu/NotificationPopupMenu'
export * from './Page/Page'
export * from './PaginatedList/PaginatedList'
export * from './ProfilePayment/ProfilePayment'
export * from './QueryResponseHandler/QueryResponseHandler'
export * from './Select/Select'
export * from './SEO'
export * from './ShaftSelect/ShaftSelect'
export * from './SignSocial/SignSocial'
export * from './Table/Table'
export * from './ProfilePayout/ProfilePayout'
export * from './ExperienceLevelSelect/ExperienceLevelSelect'
export * from './Sections/Sections'
