import { golfClubModel } from '@graphql'
import { usePagination, useWindowSize } from '@hooks'
import { useLocation } from '@reach/router'
import { CatalogItem, GolfClubModel, GolfItem } from '@types'
import { navigate } from 'gatsby'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

interface UseGolfClubModelSearchConfig {
  withoutUrlSearchParams?: boolean
}

const useGolfClubModelSearch = (config?: UseGolfClubModelSearchConfig) => {
  const withoutUrlSearchParams = config?.withoutUrlSearchParams
  const { toRender: isMobile } = useWindowSize(['mobile', 'landscape'])
  const { search: params, pathname } = useLocation()

  const urlSearchParams = new URLSearchParams(params)

  const initialSearch = urlSearchParams.get('term') || ''

  const [search, setSearch] = useState<string | undefined>()

  useEffect(() => {
    setSearch(initialSearch)
  }, [])

  const { content = [], loading, refetch } = usePagination<CatalogItem>(
    golfClubModel.SearchCatalogueItems,
    { sortBy: 'MOST_POPULAR' },
    {
      fetchPolicy: 'no-cache'
    },
    1,
    7
  )

  const openCataloguePageWithSearch = async () => {
    const isCataloguePage = pathname.includes('catalogue')
    urlSearchParams.delete('optionIds')
    if (!isCataloguePage) {
      await navigate('/catalogue' + '?' + urlSearchParams.toString())
      return
    }
    if (isMobile) {
      urlSearchParams.delete('categoryIds')
      urlSearchParams.set('mobileTerm', search || '')
      navigate(decodeURIComponent('?' + urlSearchParams.toString()))
    }
  }

  const handleUrlSearchQueryClear = async () => {
    setSearch('')
    if (!withoutUrlSearchParams) {
      urlSearchParams.delete('term')
      await refetch({ term: '' })
      navigate(decodeURIComponent('?' + urlSearchParams.toString()))
    }
  }

  const debouncedFetch = useCallback(
    debounce(async (search: string) => {
      await refetch({ term: search })
    }, 500),
    []
  )

  const handleSearch = (search: string) => {
    setSearch(search)
    debouncedFetch(search)
    if (!withoutUrlSearchParams) {
      urlSearchParams.set('term', search)
      navigate(decodeURIComponent('?' + urlSearchParams.toString()))
    }
  }

  return {
    handleSearch,
    handleUrlSearchQueryClear,
    search,
    setSearch,
    catalogItems: content,
    loading,
    initialSearch,
    openCataloguePageWithSearch,
    params
  }
}

export default useGolfClubModelSearch
