import { gql } from '@apollo/client'
import { OrderAttrs } from '@fragments'

export const CreateOrder = gql`
  mutation CreateOrder($order: OrderParams) {
    res: createOrder(order: $order) {
      messages {
        code
        field
        message
        options {
          key
          value
        }
        template
      }
      result {
        stripeSessionId
        id
      }
      successful
    }
  }
`

export const FetchPaginateOrders = gql`
  query FetchPaginatedOrders(
    $page: Int = 1
    $pageSize: Int = 30
    $status: OrderStatus
    $term: String
    $viewAsSeller: Boolean = false
  ) {
    res: paginatedOrders(page: $page, pageSize: $pageSize, status: $status, term: $term, viewAsSeller: $viewAsSeller) {
      entries {
        ...OrderAttrs
      }
      pageNumber
      pageSize
      totalEntries
      totalPages
    }
  }
  ${OrderAttrs}
`

export const FetchOrder = gql`
  query Order($id: UUID4!) {
    res: order(id: $id) {
      ...OrderAttrs
    }
  }
  ${OrderAttrs}
`
