import { gql } from '@apollo/client'
import { PurchaseRequestAttrs } from './commonTypes'

export const GolfClubModelAttrs = gql`
  fragment GolfClubModelAttrs on GolfClubModel {
    additionalInformation
    avatar
    avatars
    description
    golfClubTypes {
      bounce
      clubHeadVolume
      condition
      dexterity
      flex
      id
      insertedAt
      length
      lieAngle
      loft
      option
      swingWeight
      totalWeight
      updatedAt
      golfClubModel {
        highestBuyPrice
        lowestSellPrice
      }
      purchaseRequests {
        ...PurchaseRequestAttrs
      }
      openPurchaseRequests {
        buyerTotalPrice
        itemPrice
      }
      golfClubsOnSale {
        price
      }
      golfClubs {
        expiresAt
        id
        insertedAt
        price
        soldAt
        status
        updatedAt
      }
    }
    highestBuyPrice
    id
    insertedAt
    lowestSellPrice
    releaseYear
    retailPrice
    standardLength
    title
    slug
    updatedAt
    variants
    brand {
      avatar
      id
      insertedAt
      title
      updatedAt
    }
    category {
      id
      insertedAt
      title
      updatedAt
    }
  }
  ${PurchaseRequestAttrs}
`
export const GolfClubModelMarketDataHistoryAttrs = gql`
  fragment GolfClubModelMarketDataHistoryAttrs on GolfClubModel {
    golfClubTypes {
      condition
      dexterity
      flex
      id
      length
      option
      updatedAt
      purchaseRequests {
        itemPrice
        id
        status
        updatedAt
      }
      golfClubs {
        id
        price
        status
        updatedAt
      }
    }
    id
  }
`

export const GolfClubModelMyInventoryAttrs = gql`
  fragment GolfClubModelMyInventoryAttrs on GolfClubModel {
    avatar
    title
    slug
    releaseYear
  }
`

export const GolfClubModelBrowsingHistoryAttrs = gql`
  fragment GolfClubModelBrowsingHistoryAttrs on GolfClubModel {
    id
    avatar
    title
    slug
    lowestSellPrice
    highestBuyPrice
    brand {
      title
    }
  }
`
