import React, { useState, useEffect, RefObject } from 'react'
import {
  withOptionSelect,
  WithOptionSelectProps,
  WithOptionSelectPropsPassed,
  withToggle,
  WithToggleProps,
  WithTogglePropsPassed
} from '@hocs'

import { Dropdown, DropdownProps } from '@elements'
import Input, { InputProps } from '../../elements/__Input/Input'
import { OptionType } from '@types'
import AutocompleteArrow from '@images/select-arrow.svg'
import './Autocomplete.scss'

type AutocompleteInputProps = Omit<
  InputProps,
  | 'name'
  | 'label'
  | 'value'
  | 'type'
  | 'defaultValue'
  | 'disabled'
  | 'error'
  | 'loading'
  | 'clearable'
  | 'required'
  | 'hideCursor'
  | 'ref'
  | 'onChange'
  | 'onFocus'
  | 'onBlur'
  | 'onKeyDown'
  | 'onClear'
>

type AutocompleteDropdownProps = Omit<DropdownProps, 'options' | 'onSelect' | 'toggle'>

export type AutocompleteProps = {
  name: string
  label?: string
  initialSearch?: string
  disabled?: boolean
  required?: boolean
  clearable?: boolean
  error?: boolean
  loading?: boolean
  innerRef?: RefObject<HTMLInputElement>
  disableFilter?: boolean

  input?: AutocompleteInputProps
  dropdown?: AutocompleteDropdownProps
  hasArrow?: boolean
  placeholder?: string

  onInputChange?: (value: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  onClear?: () => void
} & WithToggleProps &
  WithOptionSelectProps

export type AutocompletePropsWithHocs = AutocompleteProps & WithOptionSelectPropsPassed & WithTogglePropsPassed

const Autocomplete: React.FC<AutocompletePropsWithHocs> = props => {
  const {
    name,
    label,
    initialSearch,
    input = {},
    dropdown = {},
    innerRef,
    options,
    selectedOption,
    toggle,
    error,
    loading,
    disabled,
    required,
    clearable,
    handleOptionSelect,
    handleToggle,
    onInputChange,
    onFocus,
    onBlur,
    onKeyDown,
    onClear,
    disableFilter,
    hasArrow,
    placeholder
  } = props

  const [autocomplete, setAutocomplete] = useState(initialSearch || '')


  useEffect(() => {
    if (selectedOption) {
      setAutocomplete('')
    }
  }, [selectedOption])

  const handleInputChange = (value: string) => {
    handleOptionSelect(null)
    handleToggle(true)
    setAutocomplete(value)

    if (onInputChange) onInputChange(value)
  }

  const handleAutocompleteFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    /* If button in the suffix is clicked don't open the dropdown */
    // if (e.target instanceof HTMLButtonElement && !error) return
    // handleToggle(true)
    // if (onFocus) onFocus(e)
    return null
  }

  const handleAutocompleteBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      if (!selectedOption) setAutocomplete('')

      handleToggle(false)
      if (onBlur) onBlur(e)
    }
  }

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) onKeyDown(e)
  }

  const handleInputClear = () => {
    handleOptionSelect(null)
    setAutocomplete('')
    if (onClear) onClear()
  }

  const handleDropdownSelect = (option: OptionType) => {
    handleOptionSelect(option)
    setAutocomplete(option.label)
    handleToggle(false)
  }

  /* If disabled no focus */
  const tabIndex = disabled ? -2 : 0

  const { suffix, direction, autoComplete: autocompleteEnabledOption, ...otherInputProps } = input

  const { ...otherDropdownProps } = dropdown

  const filteredOptions = disableFilter
    ? options
    : options.filter(option => option.label.toLowerCase().includes(autocomplete.toLowerCase()))


  return (
    <div
      className={'autocomplete'}
      tabIndex={tabIndex}
      onFocus={handleAutocompleteFocus}
      // onFocus={() => null}
      onBlur={handleAutocompleteBlur}
      data-disabled={disabled}
    >
      <Input
        placeholder={placeholder}
        name={name}
        label={label}
        value={selectedOption?.shortLabel || selectedOption?.label || autocomplete}
        ref={innerRef}
        onChange={handleInputChange}
        onKeyDown={handleInputKeyDown}
        onClear={handleInputClear}
        disabled={disabled}
        error={error}
        loading={loading}
        required={required}
        clearable={clearable}
        autoComplete={'off'}
        direction={direction}
        hideCursor
        // type={'search'}
        type={'text'}
        suffix={
          <>
            {hasArrow && <AutocompleteArrow className={'autocomplete-arrow'} data-toggle={!disabled && toggle} />}
            {suffix}
          </>
        }
        {...otherInputProps}
      />
      <Dropdown
        options={filteredOptions}
        toggle={!disabled && toggle}
        direction={direction}
        onSelect={handleDropdownSelect}
        {...otherDropdownProps}
      />
    </div>
  )
}

Autocomplete.defaultProps = {
  input: {
    placeholder: 'Select an option'
  },
  hasArrow: true,
  dropdown: {}
}

export default withOptionSelect(withToggle(Autocomplete))
