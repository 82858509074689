import { CatalogItem, GolfClubModel } from '@types'
import React, { createContext, useState } from 'react'

interface CatalogueScrollContextProps {
  scrollLocation: number | null
  setScrollLocation: React.Dispatch<React.SetStateAction<number | null>>
  list: CatalogItem[]
  setList: React.Dispatch<React.SetStateAction<CatalogItem[]>>
  clubList: GolfClubModel[]
  setClubList: React.Dispatch<React.SetStateAction<GolfClubModel[]>>
}

export const CatalogueScrollContext = createContext<CatalogueScrollContextProps>({
  scrollLocation: null,
  setScrollLocation: () => {},
  list: [],
  setList: () => {},
  clubList: [],
  setClubList: () => {}
})

const CatalogueLockScrollProvider = ({ children }) => {
  const [scrollLocation, setScrollLocation] = useState<number | null>(0)
  const [list, setList] = useState<CatalogItem[]>([])
  const [clubList, setClubList] = useState<GolfClubModel[]>([])
  return (
    <CatalogueScrollContext.Provider
      value={{
        list,
        setList,
        scrollLocation,
        setScrollLocation,
        clubList,
        setClubList
      }}
    >
      {children}
    </CatalogueScrollContext.Provider>
  )
}

export default CatalogueLockScrollProvider
