import { gql } from '@apollo/client'
import { GolfEquipmentAttr, GolfEquipmentModelMarketDataHistoryAttrs } from '@fragments'

export const FetchGolfEquipment = gql`
  query FetchGolfEquipment($id: UUID4!) {
    res: golfEquipment(id: $id) {
      ...GolfEquipmentAttr
    }
  }
  ${GolfEquipmentAttr}
`

export const FetchGolfEquipmentModel = gql`
  query FetchGolfEquipmentModel($id: UUID4!) {
    res: golfEquipmentModel(id: $id) {
      ...GolfEquipmentModelMarketDataHistoryAttrs
    }
  }

  ${GolfEquipmentModelMarketDataHistoryAttrs}
`
