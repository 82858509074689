import { useMutation } from '@apollo/client'
import {
  GolfEquipment,
  ExtendGolfEquipmentArgs,
  GolfEquipmentParams,
  MutationResponse,
  ResponseType,
  UpdateGolfEquipmentArgs,
  PaginatedCatalogItems
} from '@types'
import { equipment, golfItems as graphqlGolfItems } from '@graphql'
import { useRequestErrorHandler } from '@hooks'
import { useAlert } from 'react-alert'

type GolfEquipmentResponse = ResponseType<MutationResponse & { result: GolfEquipment }>

const useGraphqlGolfEquipment = () => {
  const handleRequestError = useRequestErrorHandler()
  const alert = useAlert()

  const [createGolfEquipmentMutation, { loading: isLoadingCreateGolfEquipmentMutation }] = useMutation<
    ResponseType<MutationResponse>,
    GolfEquipmentParams
  >(equipment.CreateGolfEquipment)

  const createGolfEquipment = async (createArgs: GolfEquipmentParams) => {
    await createGolfEquipmentMutation({ variables: createArgs })
  }

  const [updateGolfEquipmentMutation, { loading: updateGolfEquipmentLoading }] = useMutation<
    GolfEquipmentResponse,
    UpdateGolfEquipmentArgs
  >(equipment.UpdateGolfEquipment, {
    onError: error => handleRequestError(null, error)
  })

  const [extendGolfEquipmentMutation, { loading: extendGolfEquipmentLoading }] = useMutation<
    GolfEquipmentResponse,
    ExtendGolfEquipmentArgs
  >(equipment.ExtendGolfEquipment, {
    onError: error => handleRequestError(null, error)
  })

  const [deleteGolfEquipmentMutation, { loading: deleteGolfEquipmentLoading }] = useMutation<
    GolfEquipmentResponse,
    { id: string }
  >(equipment.DeleteGolfEquipment)

  const [createGolfEquipmentModelAlert, { loading: createGolfEquipmentModelAlertLoading }] = useMutation<
    ResponseType<MutationResponse>,
    { golfEquipmentModelAlert: { email: string; golfEquipmentModelId: string } }
  >(equipment.CreateGolfEquipmentModelAlert, {
    update(cache, data) {
      if (data.data?.res.successful)
        alert.show(`Successful!`, {
          type: 'success'
        })
    }
  })

  const updateGolfEquipment = async (updateArgs: UpdateGolfEquipmentArgs) => {
    const { id } = updateArgs
    const updateRequest = await updateGolfEquipmentMutation({
      variables: { ...updateArgs },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res?.successful
        const result = mutationResult.data?.res?.result
        if (success) {
          const normalizedId = cache.identify({ id, __typename: 'GolfClub' })
          cache.modify({
            id: normalizedId,
            fields(existingValue, { fieldName }) {
              const newValue = result?.[fieldName as keyof GolfEquipment]
              return newValue || existingValue
            }
          })
          alert.show(`Golf equipment successfully updated!`, {
            type: 'success'
          })
        }
      }
    })

    return handleRequestError<GolfEquipment>(updateRequest)
  }

  const extendGolfEquipment = async (extendArgs: ExtendGolfEquipmentArgs) => {
    const { id, expiresAt } = extendArgs

    const extendRequest = await extendGolfEquipmentMutation({
      variables: { id, expiresAt },
      update(cache, mutationResult) {
        const success = mutationResult.data?.res?.successful
        const result = mutationResult.data?.res?.result
        if (success) {
          const normalizedId = cache.identify({ id, __typename: 'GolfItem' })

          cache.modify({
            id: normalizedId,
            fields: {
              expiresAt() {
                return expiresAt
              },
              status() {
                return result?.status
              }
            }
          })
          alert.show(`Golf equipment successfully extend!`, {
            type: 'success'
          })
        }
      }
    })
    return handleRequestError<GolfEquipment>(extendRequest)
  }

  const deleteGolfEquipment = async (id: string) => {
    const deleteRequest = await deleteGolfEquipmentMutation({
      variables: { id },
      refetchQueries: [{ query: graphqlGolfItems.PaginatedGolfItems }],
      update(cache, mutationResult) {
        const success = mutationResult.data?.res?.successful

        if (success) {
          cache.modify({
            id: 'ROOT_QUERY',
            fields: {
              paginatedGolfItems(existing: PaginatedCatalogItems, { readField }) {
                const entries = existing.entries
                const filteredEntries = entries.filter(item => readField('id', item) !== id)
                return { ...existing, entries: filteredEntries }
              }
            }
          })
          alert.show(`Golf equipment successfully delete!`, {
            type: 'success'
          })
        }
      }
    })
    return handleRequestError<GolfEquipment>(deleteRequest)
  }

  return {
    createGolfEquipment,
    updateGolfEquipment,
    extendGolfEquipment,
    deleteGolfEquipment,
    createGolfEquipmentModelAlert,
    loading:
      isLoadingCreateGolfEquipmentMutation ||
      updateGolfEquipmentLoading ||
      extendGolfEquipmentLoading ||
      deleteGolfEquipmentLoading ||
      createGolfEquipmentModelAlertLoading
  }
}

export default useGraphqlGolfEquipment
