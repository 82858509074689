import { FetchResult, MutationFunctionOptions, MutationHookOptions, Reference, useMutation } from '@apollo/client'
import { navigate } from 'gatsby'
import {
  CreatePurchaseRequest,
  MutationResponse,
  PurchaseRequest,
  ResponseType,
  UpdatePurchaseRequestArgs
} from '@types'
import { useAlert } from 'react-alert'
import { useRequestErrorHandler } from '@hooks'
import { purchaseRequest } from '@graphql'

type PurchaseRequestResponse = ResponseType<MutationResponse & { result: PurchaseRequest }>

const useGraphqlPurchaseRequest = () => {
  const alert = useAlert()
  const handleRequestError = useRequestErrorHandler()

  const [createPurchaseRequestMutation, { loading: createPurchaseRequestLoading }] = useMutation<
    PurchaseRequestResponse,
    CreatePurchaseRequest
  >(purchaseRequest.CreatePurchaseRequest, { onError: error => handleRequestError(null, error) })

  const [updatePurchaseRequestMutation, { loading: updatePurchaseRequestLoading }] = useMutation<
    PurchaseRequestResponse,
    UpdatePurchaseRequestArgs
  >(purchaseRequest.UpdatePurchaseRequest, { onError: error => handleRequestError(null, error) })

  const [extendPurchaseRequestMutation, { loading: extendPurchaseRequestLoading }] = useMutation<
    PurchaseRequestResponse
  >(purchaseRequest.ExtendPurchaseRequest, {
    onError: error => handleRequestError(null, error)
  })

  const [deleteMultiplePurchaseRequestsMutation, { loading: deleteMultiplePurchaseRequestsLoading }] = useMutation<
    ResponseType<MutationResponse>
  >(purchaseRequest.DeleteMultiplyPurchaseRequests)

  const [deletePurchaseRequestMutation, { loading: deletePurchaseRequestLoading }] = useMutation<
    PurchaseRequestResponse
  >(purchaseRequest.DeletePurchaseRequest, { onError: error => handleRequestError(null, error) })

  const deletePurchaseRequest = async (purchaseRequestId: string) => {
    const request = await deletePurchaseRequestMutation({
      variables: { id: purchaseRequestId },
      update: (cache, mutationResult) => {
        const successful = mutationResult.data?.res.successful

        if (!successful) return null

        const normalizedId = cache.identify({ id: purchaseRequestId, __typename: 'PurchaseRequest' })
        cache.modify({
          fields: {
            paginatedPurchaseRequests(existing = {}, { readField }) {
              return {
                ...existing,
                entries: (existing?.entres || []).filter((ref: Reference) => readField('id', ref) === purchaseRequestId)
              }
            }
          }
        })
        cache.evict({ id: normalizedId })
        cache.gc()

        alert.show(`Success!`, {
          type: 'success'
        })
      }
    })

    return handleRequestError(request)
  }

  const createPurchaseRequest = async (purchaseRequest: CreatePurchaseRequest, options?: MutationFunctionOptions) => {
    const request = await createPurchaseRequestMutation({
      ...(options || {}),
      variables: { ...purchaseRequest },
      update(cache, mutationResult) {
        const successful = mutationResult?.data?.res?.successful
        const result = mutationResult?.data?.res?.result
        if (successful) {
          cache.modify({
            fields: {
              paginatedPurchaseRequests(existing) {
                return {
                  ...existing,
                  entries: [result, ...(existing?.entries || [])]
                }
              }
            }
          })

          navigate('/checkout-success', {
            state: {
              submitTitle: 'to offer book',
              submitUrl: '/account/my-offer-book',
              checkoutSuccessValue: 'set-buy-price'
            }
          })
        }
      }
    })
    return handleRequestError<PurchaseRequest>(request)
  }

  const updatePurchaseRequest = async (updateArgs: UpdatePurchaseRequestArgs) => {
    const request = await updatePurchaseRequestMutation({
      variables: { ...updateArgs },
      update(cache, mutationResponse) {
        const successful = mutationResponse.data?.res.successful
        const result = mutationResponse.data?.res.result
        if (!successful) return null
        const normalizedId = cache.identify({ id: updateArgs.id, __typename: 'PurchaseRequest' })
        cache.modify({
          id: normalizedId,
          fields(existingValue, { fieldName }) {
            const newValue = result?.[fieldName as keyof PurchaseRequest]
            return newValue || existingValue
          }
        })
        alert.show(`Purchase request successfully updated`, {
          type: 'success'
        })
      }
    })
    return handleRequestError<PurchaseRequest>(request)
  }

  const extendPurchaseRequest = async (id: string, expiresAt: string) => {
    const request = await extendPurchaseRequestMutation({
      variables: { expiresAt, id },
      update(cache, mutationResponse) {
        const successful = mutationResponse.data?.res.successful
        if (!successful) return null
        const normalizedId = cache.identify({ id, __typename: 'PurchaseRequest' })
        cache.modify({
          id: normalizedId,
          fields: {
            expiresAt(existing: string) {
              return expiresAt || existing
            }
          }
        })

        alert.show(`Purchase request successfully extends`, {
          type: 'success'
        })
      }
    })
    return handleRequestError<PurchaseRequest>(request)
  }

  const deleteMultiplePurchaseRequests = async (ids: string[], options?: MutationHookOptions) => {
    const request = await deleteMultiplePurchaseRequestsMutation({
      variables: { ids },
      ...options,
      update(cache, mutationResponse) {
        const successful = mutationResponse.data?.res.successful
        if (!successful) return null
        ids.forEach(id => {
          const normalizedId = cache.identify({ id, __typename: 'PurchaseRequest' })
          cache.evict({ id: normalizedId })
        })
        cache.gc()

        alert.show(`Purchase requests successfully deleted`, {
          type: 'success'
        })
      }
    })

    return handleRequestError<undefined>(request as FetchResult<ResponseType<MutationResponse & { result: undefined }>>)
  }

  return {
    deletePurchaseRequest,
    createPurchaseRequest,
    updatePurchaseRequest,
    extendPurchaseRequest,
    deleteMultiplePurchaseRequests,
    loading: updatePurchaseRequestLoading || createPurchaseRequestLoading || deletePurchaseRequestLoading
  }
}

export default useGraphqlPurchaseRequest
