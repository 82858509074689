import { gql } from '@apollo/client'

export const PromoCodeAttrs = gql`
  fragment PromoCodeAttrs on PromoCode {
    active: Boolean
    code: String
    discount: Float
    expiresAt: Date
    id: UUID4
    insertedAt: NaiveDateTime
    updatedAt: NaiveDateTime
  }
`
