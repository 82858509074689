import { navigate } from 'gatsby'
import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GolfClubModelCard, ListSection, ListSectionPropsType } from '@components'
import { useUrlSearchParams } from '@hooks'
import { GolfClubModel, ProductPageItemType, ResponseType } from '@types'
import { golfClubModel } from '@graphql'
import styles from './GolfClubModelsListSection.module.scss'

export interface GolfClubModelsListSectionProps extends ListSectionPropsType {
  sortBy: string
  noItemMessage?: string
}

const GolfClubModelsListSection: React.FC<GolfClubModelsListSectionProps> = props => {
  const { sortBy, noItemMessage, ...listSectionProps } = props
  const { getUrlSearchParam } = useUrlSearchParams('categoryIds')
  const categoryId = getUrlSearchParam()
  const categoryIds = categoryId && categoryId.length ? [categoryId] : undefined

  const { data, loading, error, refetch } = useQuery<
    ResponseType<GolfClubModel[]>,
    { sortBy: string; categoryIds: string[] | undefined }
  >(golfClubModel.LimitGolfClubModels, { variables: { sortBy, categoryIds } })

  useEffect(() => {
    refetch({ sortBy, categoryIds })
  }, [categoryId])

  const handleGolfClubModelClick = (golfClubModel: GolfClubModel) => {
    const isGolfClub = golfClubModel.type === 'GOLF_CLUB_MODEL'
    const itemType: ProductPageItemType = isGolfClub ? 'GolfClubModel' : 'GolfEquipmentModel'

    let link = `/product/${golfClubModel.slug}/${itemType}`
    navigate(link)
  }

  return (
    <div className={styles.gcmListSection}>
      <ListSection
        list={data?.res || []}
        loading={loading}
        error={error}
        onItemClick={handleGolfClubModelClick}
        Component={({ listData }) => {
          return <GolfClubModelCard value={sortBy} golfModel={listData} />
        }}
        noItemMessage={noItemMessage}
        {...listSectionProps}
      />
    </div>
  )
}

export default GolfClubModelsListSection
