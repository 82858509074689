import { gql } from '@apollo/client'
import { PurchaseRequestAttrs } from './commonTypes'

export const GolfClubTypeAttrs = gql`
  fragment GolfClubTypeAttrs on GolfClubType {
    bounce
    brand {
      avatar
      id
      title
      insertedAt
      updatedAt
    }
    category {
      id
      title
      insertedAt
      updatedAt
    }
    clubHeadVolume
    condition
    dexterity
    flex
    golfClubModel {
      additionalInformation
      avatar
      avatars
      description
      highestBuyPrice
      id
      insertedAt
      lowestSellPrice
      releaseYear
      retailPrice
      slug
      standardLength
      title
      updatedAt
      variants
    }
    golfClubs {
      expiresAt
      id
      insertedAt
      price
      soldAt
      status
      updatedAt
    }
    id
    insertedAt
    length
    lieAngle
    loft
    option
    openPurchaseRequests {
      buyerTotalPrice
      sellerProfit
      id
      user {
        id
      }
    }
    golfClubsOnSale {
      price
      id
      owner {
        id
      }
    }
    purchaseRequests {
      ...PurchaseRequestAttrs
    }
    swingWeight
    totalWeight
    updatedAt
  }
  ${PurchaseRequestAttrs}
`
